@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  font-family: "League Spartan", sans-serif;
}

.facebook {
  color: #3b5998;
  font-size: 2.5em;
}

.social a {
  padding: 10px;
}

.facebook:hover,
.linkedin:hover,
.github:hover,
.twitter:hover,
.google:hover {
  color: #d5d5d5;
}

.linkedin {
  color: #1e87b7;
  font-size: 25px;
}

.google {
  color: #dd4b39;
  font-size: 25px;
}

.facebook {
  color: #3d5da2;
  font-size: 25px;
}

.github {
  color: #e4dfdf;
  font-size: 25px;
}

.twitter {
  color: #1d9bf0;
  font-size: 25px;
}

.google1 {
  color: #e76554;
  font-size: 25px;
}

/* NEW CSS */

.bgContainer {
  background: #152d35;
}

.textColor {
  color: #00af91;
}

.textColorHover:hover {
  color: #00af91;
}

.line-after {
  position: relative;
  overflow: hidden;
  display: block;
}
.line-after span {
  vertical-align: baseline;
  display: block;
  position: relative;
}
.line-after span:after {
  content: "";
  position: absolute;
  width: 100%;
  top: 50%;
  margin-left: 10px;
  border-top: 1px solid rgb(255, 255, 255);
  /* border-top: 1px solid rgb(129, 140, 248); */
}

.demo-editor {
  margin-bottom: 50px;
  padding: 5px;
  color: black;
}

.demo-wrapper {
  padding: 8px;
  color: black;
}

.codeCheck {
  font-family: MyFancyCustomFont, monospace;
  font-size: inherit;
  background: #ebebeb;
  word-wrap: break-word;
  box-decoration-break: clone;
  padding: 0.1rem 0.8rem 0.2rem;
  border-radius: 0.2rem;
  color: rgb(0, 0, 0);
  margin-bottom: 20px;
  margin-top: 10px;
}

.superFancyBlockquote {
  color: rgb(255, 255, 255);
  font-style: italic;
  text-align: center;
  border-left: 3px solid #ebebeb;
  padding: 0.1rem 0.8rem 0.2rem;
}

/* Development languages animation */
.devLanguageItem {
  --animate-duration: 5s;
}